import { Component, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfstudyService } from '../../../services/profstudy/profstudy.service';
import { Subscription } from 'rxjs';
import { Profstudy, SelectedProfstudies } from '../../../models/profstudy2';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { FilePickerComponent } from '../../../components/filepicker/filepicker.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmandsubmitComponent } from '../profstudyreview/confirmandsubmit/confirmandsubmit.component';
import { ProfstudyreviewComponent } from '../profstudyreview/profstudyreview.component';
import { CommentComponent } from './comment/comment.component';

@Component({
  selector: 'app-profstudydetail',
  templateUrl: './profstudydetail.component.html',
  styleUrls: ['./profstudydetail.component.css']
})
export class ProfstudydetailComponent {

  private sub:any;
  private profstudies = [];
  private profstudySubscription : Subscription;
  private loginSubscription : Subscription;
  private isLoaded : boolean;
  private showFilePicker : boolean;
  private isLoggedIn : boolean;
  private user:any;
  public selectedProfstudies:SelectedProfstudies;
  private params : any;
  public roundStatus : string;
  public found : boolean;

  public studyTypes : Array<any>;
  private studies : Object;
  private fileInput : any;
  private profreview : any;
  private submitData : any;
  private confirmedDataSubscription : Subscription;
  private currentStudyId : any;
  private currentStudy : any;
  public browserIsIE : boolean;
  private isInitialized : boolean;

  @Output() roundSelected = new EventEmitter<String>();
  constructor(private route:ActivatedRoute, private profstudyService:ProfstudyService, private authorizedService:AuthorizedService, private modalService:NgbModal) {
    this.selectedProfstudies = new SelectedProfstudies();
    this.selectedProfstudies.purchasedStudies.push(new Profstudy());
    this.isLoaded = false;
    this.isLoggedIn = false;
    this.profstudies = null;
    this.roundStatus = null;
    this.isInitialized = false;
    this.showFilePicker = false;
    this.loginSubscription = this.authorizedService.getLoginStatus().subscribe(user => this.loggedIn(user));
    this.studyTypes = [];
    this.studies = new Object();
    this.sub = this.route.params.subscribe(params => {
      this.params = params;
      this.profstudySubscription = this.profstudyService.profstudySubject.subscribe(profstudies => this.recievedProfstudies(profstudies));
   });
   
   if(document['documentMode'] == null){
     this.browserIsIE = false;
   }
   else{
     this.browserIsIE = true;
   }
  }
  ngOnDestroy(){
    if(this.loginSubscription){
      this.loginSubscription.unsubscribe();
    }
    if(this.confirmedDataSubscription){
      this.confirmedDataSubscription.unsubscribe();
    }
    if(this.profstudySubscription){
      this.profstudySubscription.unsubscribe();
    }
    
  }

  recievedProfstudies(profstudies){
      this.profstudies = profstudies;
      this.profstudyService.setUpProfstudyDetails(this.params.roundName);
      this.selectedProfstudies = this.profstudyService.selectedProfstudies;
      this.studyTypes = this.profstudyService.studyTypes;
      this.studies = this.profstudyService.studies;
      this.found = this.profstudyService.found;
      this.roundStatus = this.profstudyService.detailsRoundStatus;
   }

  loggedIn(user){
    if(user.username != null){
      this.selectedProfstudies = new SelectedProfstudies();
      this.selectedProfstudies.purchasedStudies.push(new Profstudy());
      this.studies = new Object();
      this.roundStatus = null;
      this.profstudies = null;
      this.isLoggedIn = true;
      this.studyTypes = [];
      this.user = user;
      
    }
    else{
      
      this.isLoggedIn = false;
      this.user = null;
    }

  }


  createLink(docType, id){
    if(this.user != null && this.user.username !=null && this.selectedProfstudies !=null &&this.selectedProfstudies.purchasedStudies !=null){
      var linkBase = "/refcig-services/authority/" + this.user.authorityInstitution[0].authority + "/download/profstudy/" + this.selectedProfstudies.purchasedStudies[0].id;
      var URL;
      if(docType == 'protocol'){
        URL = linkBase + "/Protocol";
      }
      else if(docType == 'interim'){
        URL = linkBase + '/InterimReport';
      }
      else if(docType == 'final'){
        URL = linkBase + '/FinalReport';
      }
      else if(docType == 'submittedData'){
        linkBase = "/refcig-services/authority/" + this.user.authorityInstitution[0].authority + "/download/profstudy/" + id;
        URL = linkBase + '/SubmittedData';
      }
      if(this.browserIsIE){
        this.authorizedService.getFile(URL);
      } 
      else{
        return URL;
      } 
  }
  }

  openModal(study){
    //called when user clicks submit data button
    this.fileInput = this.modalService.open(FilePickerComponent);
    this.currentStudyId = study.id;
    this.fileInput.componentInstance.onSubmitEvent.subscribe((filelist)=>this.postProfstudyData(filelist));
    this.currentStudy = study;
  }

  openComments(study){
    var commentsInput = this.modalService.open(CommentComponent,{size:'sm', backdrop:'static', centered: true});
    commentsInput.componentInstance.profstudyId = study.id;
    if(this.profstudies.length > 0){
      commentsInput.componentInstance.roundId = this.profstudies[0].purchasedStudies[0].round.id;
    }

  }

  postProfstudyData(filelist){
    //submit user data to server and wait for response to build data review
    var formdata = new FormData();
    formdata.append('file',filelist.item(0));
    formdata.append('profstudyId',this.currentStudyId);
    this.authorizedService.postFile('/refcig-services/fillProfstudyData',formdata,this.buildDataReview,this.submitDataError,this);
  }
  submitDataError(){
    this.fileInput.close();
  }

  buildDataReview(response){
    this.fileInput.close();
    this.profreview = this.modalService.open(ProfstudyreviewComponent,{windowClass:'profstudy-review',size:'lg', backdrop:'static'});//use backdrop:'static' so user cannot click away box
    this.profreview.componentInstance.submitData = JSON.stringify(response.profstudy);
    this.profreview.componentInstance.filename = response.filename;
    this.profreview.componentInstance.confirmData = this.parseConfirmAndSubmitData(response.profstudy);
    this.profreview.componentInstance.machineData = this.parseMachineData(response.profstudy);
    this.profreview.componentInstance.sectionData = this.sortOnSections(response.profstudy);
    if(response.profstudy.profstudyPhysical != null){
      this.profreview.componentInstance.physicalData = this.parsePhysicalData(response.profstudy.profstudyPhysical);
    }
    //console.log(this.profreview.componentInstance.physicalData);
    this.profreview.componentInstance.study = this.currentStudy;
  }

  parseConfirmAndSubmitData(profstudy:Profstudy){
    var confirmData = new Object();
    confirmData['iso'] = profstudy.isoAccreditation;
    confirmData['institution'] = profstudy.institution;
    confirmData['studyId'] = profstudy.round.profStudyReferenceId;
    confirmData['dataId'] = profstudy.id;
    confirmData['productName'] = profstudy.round.product.model;
    confirmData['orderDate'] = profstudy.purchasedetail.purchase.purchaseDate;
    return confirmData;
  }
  parseMachineData(profstudy:any){
    var machineData = new Object();
    machineData['notes'] = profstudy.notes;
    machineData['architecture'] = profstudy.round.machArch;
    machineData['make'] = profstudy.profstudyData[0].machineMake;
    machineData['model'] = profstudy.profstudyData[0].machineModel;
    //debugger;
    //console.log('machine data '+machineData['architecture']);
    return machineData;
  }
  sortOnSections(profstudy:any){
    //create a map containing all of the data sections
    var data = profstudy.profstudyData;
    if(data[0].htmlDisplay !=null){
      var keys = Object.keys(data[0].htmlDisplay);
    }
    else{
      //console.log('No HTML Display information included');
      return;
    }
    
    var foundSections = new Map();
    for(var i=0; i<data.length; i++){
      var possibleSection = null;
      for(var j=0; j<keys.length; j++){
        //console.log(data[i][keys[j]]);
        //debugger;
        if(typeof(data[i][keys[j]])=="boolean" && foundSections.has(data[i].htmlDisplay[keys[j]].html) == false && data[i][keys[j]] == true){
          foundSections.set(data[i].htmlDisplay[keys[j]].html, [data[i]]);
          j+=99;
        }
        else if(typeof(data[i][keys[j]])=="boolean" && data[i][keys[j]] == true){//section already found add data
          foundSections.get(data[i].htmlDisplay[keys[j]].html).push(data[i]);
          j+=99;
        }
        else if(typeof(data[i][keys[j]])=="boolean" && data[i][keys[j]] == false){//false boolean might be section where all booleans are false
          possibleSection = data[i].htmlDisplay[keys[j]].html;
        }
        //check if no other sections found
        if(j == (keys.length - 1) && possibleSection != null){
          //check if possible section exists already
          if(foundSections.has(possibleSection)){
            foundSections.get(possibleSection).push(data[i]);
          }
          else{//section does not exist yet
            foundSections.set(possibleSection, [data[i]]);
          }
          
        }
      }
    }
    if(foundSections.size == 0){//only one section for example 2018B
      foundSections.set("Cigarette Filler Data",[data[0]]);
      for(var i=1; i < data.length; i++){
        foundSections.get("Cigarette Filler Data").push(data[i]);
      }
      
    }
    //foundSections is a map containing all data sections and their corresponding data
    //need to get table and general data, then sort
    var sectionIterator = foundSections.keys();
    var section = sectionIterator.next();
    var sectionDisplay = {};
    var totalDisplay = [];
    while(section.done == false){
      var group1 = [];
      var group2 = [];
      var sectionDisplay = {};
      sectionDisplay['sectionName'] = section.value;
      var sectionData = foundSections.get(section.value)[0];
      var htmlDisplay = sectionData.htmlDisplay;
      var htmlKeys = Object.keys(htmlDisplay);
      //need to find the excel row that contains the table data 1/23/25 situations where it's impossible to tell which type of data arise otherwise
      //loop through and find two entries that have the same row
      let rowCount = new Object();
      for(let i=0;i<htmlKeys.length;i++){
        let r = htmlDisplay[htmlKeys[i]].cellRow;
        if(rowCount[r] == null){
          rowCount[r] = 1;
        }
        else{
          rowCount[r] ++;
        }
      }
      let rrows = Object.keys(rowCount);
      var dataRow = null;
      for(let i=0; i<rrows.length;i++){
        if(rowCount[rrows[i]] > 4){
          dataRow = rrows[i];
        }
      }

      for(let i=0;i<htmlKeys.length;i++){
        if(htmlKeys[i] != "machineMake" && htmlKeys[i] != "machineModel" && typeof(sectionData[htmlKeys[i]]) != "boolean"){
          //html key is either tabledata or general data
          //Split table and general data into two different groups table data will all have the same excel row
          var obj = new Object();
          obj['name'] = htmlKeys[i]; obj['cellRow'] = htmlDisplay[htmlKeys[i]].cellRow;
          obj['html'] = htmlDisplay[htmlKeys[i]].html; obj['cellColumn'] = htmlDisplay[htmlKeys[i]].cellColumn;
          if(obj['cellRow'] == dataRow){
            group2.push(obj);
          }
          else{
            group1.push(obj);
          }

        }
      }//end of for loop
      //sort group1 and group2 and figure out which is table data vs general data
      if(group1[0].cellRow == group1[1].cellRow){//group 1 is table data
        group1.sort(this.compareCol);
        group2.sort(this.compareRow);
        sectionDisplay['tableDisplay'] = group1;
        sectionDisplay['generalData'] = group2;
      }
      else{//group2 is table data
        group1.sort(this.compareRow);
        group2.sort(this.compareCol);
        sectionDisplay['tableDisplay'] = group2;
        sectionDisplay['generalData'] = group1;
      }
      sectionDisplay['tableData'] = foundSections.get(section.value);
      sectionDisplay['methodList'] = this.checkForMethodList(sectionDisplay['tableData']);
      totalDisplay.push(sectionDisplay);
      
      section = sectionIterator.next();
    }//end of while
    totalDisplay.sort((a,b)=>{
      if(a.tableDisplay[0].cellRow < b.tableDisplay[0].cellRow){
        return -1;
      }
      return 1;
    });

    return totalDisplay;
  }
  ////////////////////////////////////////
  checkForMethodList(tableData){
    //tableData is an array of data that maycontain a MethodList
    if(tableData[0].MethodList == null){
      return null;
    }
    var combinedList = [];
    for(var i=0;i<tableData.length;i++){
      if(typeof(tableData[i].MethodList)=='object'){
        //match htmldisplay info with value from spreadsheet
        var keys = Object.keys(tableData[i].MethodList.htmlDisplay)
        for(var j=0;j<keys.length;j++){
          var obj = tableData[i].MethodList.htmlDisplay[keys[j]];
          obj['value'] = tableData[i].MethodList[keys[j]];
          obj['name'] = keys[j];
          combinedList.push(obj);
        }
        i+=99;
      }
    }
    //sort combinedList based on cell row value
    combinedList.sort(this.compareRow);
    let dataRows = [];
    //split them based on their rows
    var rowArray = [];
    var currentRow = combinedList[0].cellRow;
    for(var i=0;i<combinedList.length;i++){
      if(combinedList[i].cellRow == currentRow){
        rowArray.push(combinedList[i]);
      }
      else{//on to next row
        currentRow = combinedList[i].cellRow;
        dataRows.push(rowArray);
        rowArray = [];
        rowArray.push(combinedList[i]);
      }
      if(i==combinedList.length-1){
        dataRows.push(rowArray);
      }
    }
    //need to sort datarows based on column now
    for(var i=0;i<dataRows.length;i++){
      dataRows[i].sort(this.compareCol);
    }
    return dataRows;
  }

  parsePhysicalData(physicalData){

    //with 2023b need to find sections first.  2023b has physicals for both 1r6f and 2r5f
    var foundSections = new Map();
    var returnSections = [];
    
    //sections are based on unique section html names
    //need to loop through all data to find all possible section titles;
    for(var i=0;i<physicalData.length;i++){
      let physicalKeys = Object.keys(physicalData[i].htmlDisplay);
      for(var j=0; j < physicalKeys.length;j++){
        if(typeof(physicalData[i][physicalKeys[j]]) == 'boolean'){
          //found a section add it
          //This assumes that all boolean xml variables have the same html
            //check if section is already found
            if(foundSections.get(physicalData[i].htmlDisplay[physicalKeys[j]].html) != null){
              foundSections.get(physicalData[i].htmlDisplay[physicalKeys[j]].html).push(physicalData[i]);
              j+=99;
            }
            else{//found a new section
              foundSections.set(physicalData[i].htmlDisplay[physicalKeys[j]].html,[physicalData[i]]);
              j+=99
            }
          }
        }
      }
      var returnObj = new Array();
    if(foundSections.size == 0){
      var retObj = new Array();
      var foundMachines = this.findMachines(physicalData);
      var physicalTableSections = this.getPhysicalTableSections(physicalData);
      var physicals = this.getPhysicals(physicalData,physicalTableSections);
      for(let i=0; i<physicals.length; i++){
        retObj.push({
                        'tableData':physicals[i],
                        'html':physicalTableSections[i],
                        'machines':foundMachines[i]});
      }
      returnObj.push({'sectionName':"PhysicalParameters",
                        'data':retObj});
    }
    else{
      //data is already divided, but need to calculate the foundMachines, Physicals, and Physical Table Sections
      
      var sectionKeys = foundSections.keys();
      var section = sectionKeys.next();
      while(section.done == false){
        let machines = this.findMachines(foundSections.get(section.value));
        let physicalTableSections = this.getPhysicalTableSections(foundSections.get(section.value));
        let physicals = this.getPhysicals(foundSections.get(section.value), physicalTableSections);
        var retObj = new Array();
        for(let j=0; j<physicals.length; j++){
          retObj.push({'tableData':physicals[j],'html':physicalTableSections[j],'machines':machines[j]});
        }
        returnObj.push({'sectionName':section.value,
                        'data':retObj});
        section = sectionKeys.next();
      }
      //need to sort the sections now
      
      returnObj.sort((a, b) => { 
        if(a.data[0].html[0].cellColumn < b.data[0].html[0].cellColumn){
          return 1;
        }
        return -1;
       } );
      
    }
    return returnObj;
    
    
    //old code for when there was just 1 physicals section
    //physicals now have all the actual data, physicalTableSections have the html, and foundMachines has the machine info
    //need to join all arrays together so it will work with ngFor
    /*var returnObj = [];
    for(let i=0; i<physicals.length; i++){
      returnObj.push({'tableData':physicals[i],'html':physicalTableSections[i],'machines':foundMachines[i]});
    }
    return returnObj;*/
  }

getPhysicals(physicalData, physicalTableSections){
  //have the order of the data now need to get the data numbers.
  var physicals = new Array();
  for(let i=0; i < physicalTableSections.length; i ++){
    physicals.push([]);
    for(let j=0; j < physicalData.length; j++){
      physicals[i].push([]);
      for(let k=0; k<physicalTableSections[i].length; k++){
        physicals[i][j].push(physicalData[j][physicalTableSections[i][k]['name']])
      }
    }
    
  }
  return physicals;
}

getPhysicalTableSections(physicalData){
  var physicalTableSections = new Array();
  physicalTableSections.push([]);
  var keys = Object.keys(physicalData[0]['htmlDisplay']);
  keys.forEach(function (key){
      let foundRow = false;
      let i = 0;
      //if data has sections we have to check for it or it will 
      //create another fake row
      if(typeof(physicalData[0][key])!='boolean'){
        while (!foundRow){
          if(physicalTableSections[i].length == 0){//first machine just push it into current row
            physicalTableSections[i].push(physicalData[0]['htmlDisplay'][key]);
            physicalTableSections[i][physicalTableSections[i].length - 1]['name'] = key;
            foundRow = true;
            i=0;
          }
          else{//need to check that data are on same row
            if(physicalTableSections[i][0]['cellRow'] == physicalData[0]['htmlDisplay'][key]['cellRow']){
              physicalTableSections[i].push(physicalData[0]['htmlDisplay'][key]);
              physicalTableSections[i][physicalTableSections[i].length - 1]['name'] = key;
              foundRow = true;
              i=0;
            }
            else{//data is on different rows increase row index
              if(i < physicalTableSections.length -1){
                i++;
              }
              else{//new row
                var a = [physicalData[0]['htmlDisplay'][key]];
                physicalTableSections.push(a);
                physicalTableSections[i + 1][physicalTableSections[i + 1].length - 1]['name'] = key;
                foundRow = true;
                i=0;
              }
            }
          }
        }
    }
  },this);
  //sort the data based on cell columns and row
  for(let i =0; i < physicalTableSections.length; i++){
    physicalTableSections[i].sort(function(a,b){
      if(a['cellColumn'] < b['cellColumn']){
        return -1;
      }
      return 1;
    });
  }
  physicalTableSections.sort(function(a,b){
    if(a[0]['cellRow'] < b[0]['cellRow']){
      return -1;
    }
    return 1;
  });
  return physicalTableSections;
}




findMachines(physicalData){
//first find the machines and sort them
var foundMachines = [];
foundMachines.push(new Array());
var keys = Object.keys(physicalData[0]);
var machineHtmlDisplayToCheck = null;//need to compare the same htmlDisplay attribute
keys.forEach(function (key){
  if(typeof(physicalData[0][key]) == 'object' && 
      physicalData[0][key] != null &&
      physicalData[0][key]['class'] !=null && 
      physicalData[0][key]['class'].includes('Machine')){
    //insert found machine into appropriate row
    if(machineHtmlDisplayToCheck == null){
      machineHtmlDisplayToCheck = Object.keys(physicalData[0][key]['htmlDisplay'])[0];
    }
    var foundRow = false;
    var i = 0;
    while (!foundRow){
      if(foundMachines[i].length == 0){//first machine just push it into current row
        foundMachines[i].push(physicalData[0][key]);
        foundRow = true;
        i=0;
      }
      else{//need to check that machines are on same row
        if(foundMachines[i][0]['htmlDisplay'][machineHtmlDisplayToCheck]['cellRow'] == physicalData[0][key]['htmlDisplay'][machineHtmlDisplayToCheck]['cellRow']){
          foundMachines[i].push(physicalData[0][key]);
          foundRow = true;
          i=0;
        }
        else{//machines on different rows increase row index
          if(i < foundMachines.length -1){
            i++;
          }
          else{//new row
            var a = [physicalData[0][key]];
            foundMachines.push(a);
            foundRow = true;
            i=0;
          }
        }
      }
    }
  }
},this);
//now need to sort the machines based on rows and columns

for(var i =0; i<foundMachines.length;i++){
    foundMachines[i].sort(function(a,b){
      if(a['htmlDisplay'][machineHtmlDisplayToCheck]['cellColumn'] < b['htmlDisplay'][machineHtmlDisplayToCheck]['cellColumn']){
        return -1;
      }
      return 1;
    });
}
foundMachines.sort(function(a,b){
      if(a[0]['htmlDisplay'][machineHtmlDisplayToCheck]['cellRow'] < b[0]['htmlDisplay'][machineHtmlDisplayToCheck]['cellRow']){
        return -1;
      }
      return 1;
    });
    //foundMachines now contains sorted arrays; one for each row and each 'row' array contains an array of machines sorted
    //by column.  Now we need to get the html data sorted in this same way and match up physical measurements that have machine data
    return foundMachines;
  }
///////////////////////////////////////////////////////////////


  compareRow(a,b){
    if(a.cellRow < b.cellRow){
      return -1;
    }
    return 1;
  }
  compareCol(a,b){
    if(a.cellColumn < b.cellColumn){
      return -1;
    }
    return 1;
  }
}
