import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject} from 'rxjs';

@Injectable()
export class ProductsService {

  public static NONE_CERTIFIED:number = 0;
  public static SOME_CERTIFIED:number = 1;
  public static ALL_CERTIFIED:number = 2;
  public products = null;
  private productsURL = 'refcig-services/products';
  public productsSubject = new ReplaySubject<any>();
  public selectedProductSubject = new ReplaySubject<any>();
  public selectedCategorySubject = new ReplaySubject<any>();
  private productToFetch = null;
  private requestedCategory = null;
  public activeCategory = null;
  public activeProduct = null;
  public certifiedState:number = 0;
  constructor(private http: HttpClient) { 
    this.http.get(this.productsURL).subscribe(res => this.setProducts(res), error=>this.productsError(error));
  }

  productsError(error){
    //console.log('error loading products');
  }
  getCertifiedState(category:string){
    if(category == null){
      return 0;
    }
    if(category.includes('Cigar') || category.includes('Smokeless')){//cigars or cigarettes
      return 1;
    }
    return 0;

  }
  setProducts(data: any) {
    this.products = data;
    this.productsSubject.next(this.products);
    //check if page has already requested a product
    if(this.productToFetch != null){
      if(this.getProductById(this.productToFetch) == true){
        this.productToFetch = null;
      }
    }
    if(this.selectedCategorySubject !=null){
      this.getProductsByCategory(this.requestedCategory);
      this.requestedCategory= null;
    }
  }

  setAdminProducts(products){
    for(var i=0; i< products.length; i++){
      products[i].category = 'Admin ' + products[i].category;
      for(var j=0; j<products[i].products.length; j++){
        products[i].products[j].category = products[i].category;
      }
      this.products.push(products[i]);
    }
    if(this.productToFetch != null){
      this.getProductById(this.productToFetch);
    }
    if(this.activeCategory!= null){
      this.getProductsByCategory(this.activeCategory);
    }
    this.productsSubject.next(this.products);
  }
  removeAdminProducts(){
    //need to remove the products once the user logs out
    if(this.products !=null && this.products.length > 0){
      while(this.products[this.products.length-1].category.includes('Admin')){
        this.products.pop();
      }
    }
    this.productsSubject.next(this.products);
  }

  getProductsByCategory(category){
    var found = false;
    if(this.activeCategory != category){
      this.activeProduct = null;
    }
    this.certifiedState = this.getCertifiedState(category);
    this.activeCategory = category;
    this.requestedCategory = category;
    if(this.products != null){
      var i = 0;
      while(i < this.products.length && !found){
        if(this.products[i].category === category){
          found = true;
          this.selectedCategorySubject.next(this.products[i].products);
        }
        else{
          i++;
        }
      }
    }
  }

  getProductById(id){
    this.activeProduct = id;
    if(this.products == null){
      this.productToFetch = id;
    }
    else{//products are loaded can search for it
      var found = false; var i=0;
      while(!found && i < this.products.length){
        var p = this.products[i].products.find(function(product){
          if (product.id == id){
            return product;
          }
        });
        if(p!=null){
          found = true;
        }
        else{
          i++;  
        }
      }
      if(found){
        this.selectedProductSubject.next(p);
      }
      else{
        return false;
      }
    }
    return true;
  }
}
